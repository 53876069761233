import {
  REGIONS_DATA_ERROR,
  REGIONS_DATA_FETCH,
  REGIONS_DATA_RECEIVED,
  REGION_DATA_ADD,
  REGION_DATA_EDIT,
  REGION_DATA_DELETE,
} from '../actions/types';

const defaultState = {
  error: null,
  loading: false,
  ready: false,

  Headers: [],
  Regions: [],
};

function addRegion(arr, region) {
  let copy = [...arr];
  copy.push(region);
  return copy;
}

function editRegion(arr, region) {
  let copy = [...arr];
  let idx = copy.findIndex(item => item.ID === region.ID);
  copy[idx] = region;
  return copy;
}

function deleteRegion(arr, id) {
  let copy = [...arr];
  copy.splice(copy.findIndex(item => item.ID === id), 1);
  return copy;
}

function regionsReducer(state=defaultState, action) {
  switch(action.type) {
    case REGIONS_DATA_RECEIVED: 
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        Regions: action.data.mappings,
        Headers: action.data.headers,
      };

    case REGIONS_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case REGIONS_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };

    case REGION_DATA_ADD:
      return {
        ...state,
        Regions: addRegion(state.Regions, action.region),
      };

    case REGION_DATA_EDIT:
      return {
        ...state,
        Regions: editRegion(state.Regions, action.region),
      };

    case REGION_DATA_DELETE:
      return {
        ...state,
        Regions: deleteRegion(state.Regions, action.id),
      };

    default: return state;

  }
}

export default regionsReducer;