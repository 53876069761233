import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Loadable from 'react-loadable';
import { BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider } from '@material-ui/core/styles';

import './index.css';
import './typefaces/continental-stag-sans-a/index.css';
import { store } from './store';
import Loading from './components/Loading';
import theme from './theme';

function main() {
  const loader = async () => {
    return import(/* webpackChunkName: "app" */ './App');
  };

  // Async loading support.
  const LoadableApp = Loadable({
    loader,
    loading: Loading,
    timeout: 20000,
    delay: 300,
  });

  ReactDOM.render(
    <DndProvider backend={Backend}>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <LoadableApp />
          </Router>
        </MuiThemeProvider>
      </Provider>
    </DndProvider>,
    document.getElementById('root')
  );
}


main();
