import {
  CONTACTS_DATA_ERROR,
  CONTACTS_DATA_FETCH,
  CONTACTS_DATA_RECEIVED,
  CONTACT_DATA_ADD,
  CONTACT_DATA_EDIT,
  CONTACT_DATA_DELETE,
  CONTACTS_HISTORY_RECEIVED,
} from '../actions/types';

const defaultState = {
  error: null,
  loading: false,
  ready: false,

  History: {
    history: [],
    header: [],
  },
  Contacts: [],
};

function addContact(arr, contact) {
  let copy = [...arr];
  copy.push(contact);
  return copy;
}

function editContact(arr, contact) {
  let copy = [...arr];
  let idx = copy.findIndex(item => item.ID === contact.ID);
  copy[idx] = contact;
  return copy;
}

function deleteContact(arr, id) {
  let copy = [...arr];
  copy.splice(copy.findIndex(item => item.ID === id), 1);
  return copy;
}

function contactsReducer(state=defaultState, action) {
  switch(action.type) {
    case CONTACTS_DATA_RECEIVED: 
      return {
        ...state,
        Contacts: action.data.contacts,
      };
      
    case CONTACTS_HISTORY_RECEIVED:
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        History: action.data,
      };


    case CONTACTS_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case CONTACTS_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };

    case CONTACT_DATA_ADD:
      return {
        ...state,
        Contacts: addContact(state.Contacts, action.contact),
      };

    case CONTACT_DATA_EDIT:
      return {
        ...state,
        Contacts: editContact(state.Contacts, action.contact),
      };

    case CONTACT_DATA_DELETE:
      return {
        ...state,
        Contacts: deleteContact(state.Contacts, action.id),
      };

    default: return state;

  }
}

export default contactsReducer;