import {
  COMPANIES_DATA_ERROR,
  COMPANIES_DATA_FETCH,
  COMPANIES_DATA_RECEIVED,
} from '../actions/types';

const defaultState = {
  error: null,
  loading: false,
  ready: false,

  Companies: [],
};

function companiesReducer(state=defaultState, action) {
  switch(action.type) {
    case COMPANIES_DATA_RECEIVED: 
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        Companies: action.data.companies,
      };

    case COMPANIES_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case COMPANIES_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };

    default: return state;

  }
}

export default companiesReducer;