import {
  LEGAL_DATA_ERROR,
  LEGAL_DATA_FETCH,
  LEGAL_DATA_RECEIVED,
  LEGAL_DATA_EDIT,
  LEGAL_DATA_DELETE,
  LEGAL_DATA_ADD,
  LEGAL_HISTORY_RECEIVED,
} from '../actions/types';

const defaultState = {
  error: null,
  loading: false,
  ready: false,

  History: {
    dpn: [],
    tou: [],
    impressum: [],
    headers: [],
  },
  Legal: {
    dpn: [],
    tou: [],
    impressum: [],
  },
};

function addLegal(arr, legal) {
  let copy = [...arr];
  copy.push(legal);
  return copy;
}

function editLegal(arr, legal) {
  let copy = [...arr];
  let idx = copy.findIndex(item => item.ID === legal.ID);
  copy[idx] = legal;
  return copy;
}

function deleteLegal(arr, id) {
  let copy = [...arr];
  copy.splice(copy.findIndex(item => item.ID === id), 1);
  return copy;
}

function legalReducer(state=defaultState, action) {
  switch(action.type) {
    case LEGAL_DATA_RECEIVED: 
      return {
        ...state,
        Legal: action.data,
      };
    
    case LEGAL_HISTORY_RECEIVED:
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        History: action.data,
      };

    case LEGAL_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case LEGAL_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };

    case LEGAL_DATA_ADD:
      return {
        ...state,
        Legal: {
          ...state.Legal,
          [action.tab]: addLegal(state.Legal[action.tab], action.legal),
        },
      };

    case LEGAL_DATA_EDIT:
      return {
        ...state,
        Legal: {
          ...state.Legal,
          [action.tab]: editLegal(state.Legal[action.tab], action.legal),
        },
      };

    case LEGAL_DATA_DELETE:
      return {
        ...state,
        Legal: {
          ...state.Legal,
          [action.tab]: deleteLegal(state.Legal[action.tab], action.id),
        },
      };

    default: return state;

  }
}

export default legalReducer;