import {
  PERFORMANCE_DATA_ERROR,
  PERFORMANCE_DATA_FETCH,
  PERFORMANCE_DATA_RECEIVED,
  PERFORMANCE_DEV_DATA_RECEIVED,
  PERFORMANCE_DATA_ADD,
  PERFORMANCE_DATA_EDIT,
  PERFORMANCE_DATA_DELETE,
  PERFORMANCE_DEV_DATA_ADD,
  PERFORMANCE_DEV_DATA_DELETE,
  PERFORMANCE_DEV_DATA_EDIT,
  PERFORMANCE_DEV_HISTORY_RECEIVED,
  PERFORMANCE_HISTORY_RECEIVED,
} from '../actions/types';

const defaultState = {
  error: null,
  loading: false,
  ready: false,

  History: {
    history: [],
    header: [],
  },
  DeviationHistory: {
    history: [],
    header: [],
  },
  KPIs: [],
  Deviations: [],
};

function addPerformance(arr, performance) {
  let copy = [...arr];
  copy.push(performance);
  return copy;
}

function editPerformance(arr, performance) {
  let copy = [...arr];
  let idx = copy.findIndex(item => item.ID === performance.ID);
  copy[idx] = performance;
  return copy;
}

function deletePerformance(arr, id) {
  let copy = [...arr];
  copy.splice(copy.findIndex(item => item.ID === id), 1);
  return copy;
}

function performanceReducer(state=defaultState, action) {
  switch(action.type) {
    case PERFORMANCE_DATA_RECEIVED: 
      return {
        ...state,
        KPIs: action.data.kpiDefaults,
      };

    case PERFORMANCE_DEV_DATA_RECEIVED: 
      return {
        ...state,
        Deviations: action.data.kpiDeviations,
      };

    case PERFORMANCE_DEV_HISTORY_RECEIVED:
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        DeviationHistory: action.data,
      };

    case PERFORMANCE_HISTORY_RECEIVED:
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        History: action.data,
      };

    case PERFORMANCE_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case PERFORMANCE_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };

    case PERFORMANCE_DATA_ADD:
      return {
        ...state,
        KPIs: addPerformance(state.KPIs, action.performance),
      };

    case PERFORMANCE_DATA_EDIT:
      return {
        ...state,
        KPIs: editPerformance(state.KPIs, action.performance),
      };

    case PERFORMANCE_DATA_DELETE:
      return {
        ...state,
        KPIs: deletePerformance(state.KPIs, action.id),
      };

    case PERFORMANCE_DEV_DATA_ADD: {
      return {
        ...state,
        Deviations: addPerformance(state.Deviations, action.deviation),
      };
    }

    case PERFORMANCE_DEV_DATA_DELETE: {
      return {
        ...state,
        Deviations: deletePerformance(state.Deviations, action.id),
      };
    }

    case PERFORMANCE_DEV_DATA_EDIT: {
      return {
        ...state,
        Deviations: editPerformance(state.Deviations, action.deviation),
      };
    }

    default: return state;

  }
}

export default performanceReducer;