import {
  ROLES_DATA_ERROR,
  ROLES_DATA_FETCH,
  ROLES_DATA_RECEIVED,
  ROLE_DATA_ADD,
  ROLE_DATA_EDIT,
  ROLE_DATA_DELETE,
} from '../actions/types';

const defaultState = {
  error: null,
  loading: false,
  ready: false,

  Roles: [],
};

function addRole(arr, role) {
  let copy = [...arr];
  copy.push(role);
  return copy;
}

function editRole(arr, role) {
  let copy = [...arr];
  let idx = copy.findIndex(item => item.ID === role.ID);
  copy[idx] = role;
  return copy;
}

function deleteRole(arr, id) {
  let copy = [...arr];
  copy.splice(copy.findIndex(item => item.ID === id), 1);
  return copy;
}

function rolesReducer(state=defaultState, action) {
  switch(action.type) {
    case ROLES_DATA_RECEIVED: 
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        Roles: action.data.roles,
      };

    case ROLES_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case ROLES_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };

    case ROLE_DATA_ADD:
      return {
        ...state,
        Roles: addRole(state.Roles, action.role),
      };

    case ROLE_DATA_EDIT:
      return {
        ...state,
        Roles: editRole(state.Roles, action.role),
      };

    case ROLE_DATA_DELETE:
      return {
        ...state,
        Roles: deleteRole(state.Roles, action.id),
      };

    default: return state;

  }
}

export default rolesReducer;