import {
  AUTH_AUTHENTICATING,
  AUTH_AUTHENTICATED,
  AUTH_LOADING,
  AUTH_ERROR,
} from '../actions/types';

const defaultState = {
  error: false,
  loading: false,
  authenticated: false,
  authenticating: true,
};

function authReducer(state = defaultState, action) {
  switch (action.type) {
    case AUTH_AUTHENTICATING:
      return {
        ...state,
        authenticating: action.authenticating,
        error: false,
      };

    case AUTH_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
        error: false,
      };

    case AUTH_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        authenticated: false,
        authenticating: false,
      };

    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    default:
      return state;
  }
}

export default authReducer;
