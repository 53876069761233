import {
  SUPPLIER_DATA_RECEIVED,
  SUPPLIER_DATA_FETCH,
  SUPPLIER_DATA_ERROR,
  SUPPLIER_DATA_RESET,
  SUPPLIER_SELECT,
} from '../actions/types';

const defaultState={
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render supplier view in sane state.
  selected: {},
  Supplier: {
    result: 0,
    suppliers: [],
  },
};

function supplierReducer(state = defaultState, action) {
  switch (action.type) {
    case SUPPLIER_DATA_FETCH:
      if(state.token && !action.status && state.token !== action.token) {
        // Poor mans debounce with load token check, only unset loading when token match.
        break;
      }
      return {
        ...state,
        loading: action.status,
        error: null,
      };

    case SUPPLIER_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Supplier: action.data,
      };

    case SUPPLIER_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.err,
      };

    case SUPPLIER_DATA_RESET:
      return {
        ...state,
        Supplier: {
          result: 0,
          suppliers: [],
        },
      };

    case SUPPLIER_SELECT:
      return {
        ...state,
        selected: action.selected,
      };
    
    default:
      break;
  }

  return state;
}

export default supplierReducer;
