import {
  NOTIFICATION_DATA_ADD,
  NOTIFICATION_DATA_EDIT,
  NOTIFICATION_DATA_DELETE,
  NOTIFICATIONS_DATA_FETCH,
  NOTIFICATIONS_DATA_RECEIVED,
  NOTIFICATIONS_DATA_ERROR,
  INFO_TYPES_DATA_FETCH,
  INFO_TYPES_DATA_RECEIVED,
} from '../actions/types';

const defaultState = {
  error: null,
  loading: false,
  ready: false,

  Notifications: [],
  Types: [],
};

function addNotification(arr, notification) {
  let copy = [...arr];
  copy.push(notification);
  return copy;
}

function editNotification(arr, notification) {
  let copy = [...arr];
  let idx = copy.findIndex(item => item.ID === notification.ID);
  copy[idx] = notification;
  return copy;
}

function deleteNotification(arr, id) {
  let copy = [...arr];
  copy.splice(copy.findIndex(item => item.ID === id), 1);
  return copy;
}

function notificationsReducer(state=defaultState, action) {
  switch(action.type) {
    case NOTIFICATIONS_DATA_RECEIVED: 
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        Notifications: action.data.infos,
      };

    case NOTIFICATIONS_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case NOTIFICATIONS_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };

    case NOTIFICATION_DATA_ADD:
      return {
        ...state,
        Notifications: addNotification(state.Notifications, action.notification),
      };

    case NOTIFICATION_DATA_EDIT:
      return {
        ...state,
        Notifications: editNotification(state.Notifications, action.notification),
      };

    case NOTIFICATION_DATA_DELETE:
      return {
        ...state,
        Notifications: deleteNotification(state.Notifications, action.id),
      };
    
    case INFO_TYPES_DATA_FETCH:
      return {
        ...state,
        loading: true,
        ready: false,
      };

    case INFO_TYPES_DATA_RECEIVED:
      return {
        ...state,
        loading: false,
        ready: true,
        Types: action.data.types,
      };

    default: return state;

  }
}

export default notificationsReducer;