import {
  ORGAS_DATA_ERROR,
  ORGAS_DATA_FETCH,
  ORGAS_DATA_RECEIVED,
} from '../actions/types';

const defaultState = {
  error: null,
  loading: false,
  ready: false,

  Orgas: [],
};

function orgasReducer(state=defaultState, action) {
  switch(action.type) {
    case ORGAS_DATA_RECEIVED: 
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        Orgas: action.data.organizations,
      };

    case ORGAS_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case ORGAS_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };

    default: return state;

  }
}

export default orgasReducer;