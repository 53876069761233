import {
  QUESTION_DRAGGED,
  QUESTION_TITLE_SET,
  QUESTION_DIALOG_STATE,
  QUESTION_DELETE,
  QUESTION_OPTION_SET,
  QUESTION_OPTION_ADD,
  QUESTION_OPTION_DELETE,
  QUESTIONNAIRES_DATA_ERROR,
  QUESTIONNAIRES_DATA_FETCH,
  QUESTIONNAIRES_DATA_RECEIVED,
} from '../actions/types';

const defaultState = {
  ready: false,
  loading: false,
  error: null,
  left: [ //All possible types
    {type: 'Yes-No', topic: '', question: ''},
    {type: 'Multiple Choice', topic: '', question: ''},
    {type: 'Scale', topic: '', question: ''},
  ],
  right: [],
  options: [],
  dialogOpen: false,
  dragging: false,
  Questionnaires: [],
};

function addQuestion(state, data) {
  let copy = state.right;
  copy.push(data);
  return copy;
}

function setQuestion(array, id, question) {
  let copy = [...array];
  copy[id].topic = question.topic;
  copy[id].question = question.question;
  copy[id].optional = question.optional;
  return copy;
}

function deleteQuestion(array, id) {
  let copy = [...array];
  copy.splice(id, 1);
  return copy;
}

function setOptions(array, questionIdx, optionIdx, value) {
  let copy = [...array];
  copy[questionIdx][optionIdx] = value;
  return copy;
}

function addOption(array, questionIdx) {
  let copy = [...array];
  copy[questionIdx].push('');
  return copy;
}

function removeOptions(array, questionIdx) {
  let copy = [...array];
  copy[questionIdx].pop();
  return copy;
}

function addQuestionToOptions(options, type) {
  let copy = [...options];
  copy.push(type === 'Multiple Choice' ? [''] : [null]);
  return copy;
}

function questionnaireReducer(state=defaultState, action) {
  switch(action.type) {
    case QUESTIONNAIRES_DATA_RECEIVED: 
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        Questionnaires: action.data,
      };

    case QUESTIONNAIRES_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case QUESTIONNAIRES_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };

    case 'QUESTION_DRAGGING': 
      return {
        ...state,
        dragging: action.value,
      };

    case QUESTION_DRAGGED:
      return {
        ...state,
        right: addQuestion(state, action.data),
        options: addQuestionToOptions(state.options, action.data.type),
        dialogOpen: true,
        dragging: false,
      };

    case QUESTION_TITLE_SET:
      return {
        ...state,
        right: setQuestion(state.right, action.id, action.question),
        dialogOpen: false,
      };

    case QUESTION_DIALOG_STATE:
      return {
        ...state,
        dialogOpen: action.value,
      };

    case QUESTION_OPTION_SET:
      return {
        ...state,
        options: setOptions(state.options, action.questionIdx, action.optionIdx, action.value),
      };

    case QUESTION_DELETE: 
      return {
        ...state,
        right: deleteQuestion(state.right, action.id),
        options: deleteQuestion(state.options, action.id),
      };

    case QUESTION_OPTION_ADD:
      return {
        ...state,
        options: addOption(state.options, action.questionIdx),
      };
    
    case QUESTION_OPTION_DELETE:
      return {
        ...state,
        options: removeOptions(state.options, action.questionIdx),
      };
      
    default: return state;
  }
}

export default questionnaireReducer;