import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';

import thunkMiddleware from 'redux-thunk';

import authReducer from './reducers/auth';
import eventsReducer from './reducers/events';
import trainingsReducer from './reducers/trainings';
import contactsReducer from './reducers/contacts';
import companiesReducer from './reducers/companies';
import performanceReducer from './reducers/performance';
import legalReducer from './reducers/legal';
import notificationsReducer from './reducers/notifications';
import orgasReducer from './reducers/orgas';
import questionnaireReducer from './reducers/questionnaire';
import regionsRecuder from './reducers/regions';
import rolesReducer from './reducers/roles';
import supplierReducer from './reducers/supplier';
import usersReducer from './reducers/users';

const loggerMiddleware = createLogger();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  combineReducers({
    auth: authReducer,
    companies: companiesReducer,
    contacts: contactsReducer,
    events: eventsReducer,
    legal: legalReducer,
    notifications: notificationsReducer,
    orgas: orgasReducer,
    performance: performanceReducer,
    questionnaire: questionnaireReducer,
    regions: regionsRecuder,
    roles: rolesReducer,
    supplier: supplierReducer,
    trainings: trainingsReducer,
    users: usersReducer,
  }),
  composeEnhancers(applyMiddleware(
    thunkMiddleware,

    loggerMiddleware // Must be last.
  ))
);

export default store;
