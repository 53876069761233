import {
  EVENT_DATA_ADD,
  EVENT_DATA_DELETE,
  EVENT_DATA_EDIT,
  EVENTS_DATA_ERROR,
  EVENTS_DATA_FETCH,
  EVENTS_DATA_RECEIVED,
  EVENTS_HISTORY_RECEIVED,
  EVENTS_ATTEND_RECEIVED,
  EVENTS_ATTEND_FETCH,
} from '../actions/types';

const defaultState = {
  error: null,
  loading: false,
  ready: false,
  attendanceLoading: false,

  History: {
    history: [],
    header: [],
  },
  Events: [],
  Attend: {
    attendance: [],
    header: [],
  },
};

function addEvent(arr, event) {
  let copy = [...arr];
  copy.push(event);
  return copy;
}

function editEvent(arr, event) {
  let copy = [...arr];
  let idx = copy.findIndex(item => item.ID === event.ID);
  copy[idx] = event;
  return copy;
}

function deleteEvent(arr, id) {
  let copy = [...arr];
  copy.splice(copy.findIndex(item => item.ID === id), 1);
  return copy;
}

function eventsReducer(state=defaultState, action) {
  switch(action.type) {
    case EVENTS_DATA_RECEIVED: 
      return {
        ...state,
        Events: action.data.events,
      };

    case EVENTS_HISTORY_RECEIVED:
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        History: action.data,
      };

    case EVENTS_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case EVENTS_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };
    
    case EVENT_DATA_ADD:
      return {
        ...state,
        Events: addEvent(state.Events, action.event),
      };

    case EVENT_DATA_EDIT:
      return {
        ...state,
        Events: editEvent(state.Events, action.event),
      };

    case EVENT_DATA_DELETE:
      return {
        ...state,
        Events: deleteEvent(state.Events, action.id),
      };

    case EVENTS_ATTEND_RECEIVED:
      return {
        ...state,
        Attend: action.data,
        attendanceLoading: false,
      };

    case EVENTS_ATTEND_FETCH:
      return {
        ...state,
        attendanceLoading: true,
        Attend: {
          attendance: [],
          header: [],
        },
      };

    default: return state;

  }
}

export default eventsReducer;