import {
  TRAININGS_DATA_ERROR,
  TRAININGS_DATA_FETCH,
  TRAININGS_DATA_RECEIVED,
  TRAINING_DATA_ADD,
  TRAINING_DATA_DELETE,
  TRAINING_DATA_EDIT,
  TRAININGS_HISTORY_RECEIVED,
} from '../actions/types';

const defaultState = {
  error: null,
  loading: false,
  ready: false,

  History: {
    history: [],
    header: [],
  },
  Trainings: [],
};

function addTraining(arr, training) {
  let copy = [...arr];
  copy.push(training);
  return copy;
}

function editTraining(arr, training) {
  let copy = [...arr];
  let idx = copy.findIndex(item => item.ID === training.ID);
  copy[idx] = training;
  return copy;
}

function deleteTraining(arr, id) {
  let copy = [...arr];
  copy.splice(copy.findIndex(item => item.ID === id), 1);
  return copy;
}

function trainingsReducer(state=defaultState, action) {
  switch(action.type) {
    case TRAININGS_DATA_RECEIVED: 
      return {
        ...state,
        Trainings: action.data.trainings,
      };

    case TRAININGS_HISTORY_RECEIVED:
      return {
        ...state,
        error: null,
        ready: true,
        loading: false,
        History: action.data,
      };

    case TRAININGS_DATA_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case TRAININGS_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.error,
      };

    case TRAINING_DATA_ADD:
      return {
        ...state,
        Trainings: addTraining(state.Trainings, action.training),
      };

    case TRAINING_DATA_EDIT:
      return {
        ...state,
        Trainings: editTraining(state.Trainings, action.training),
      };

    case TRAINING_DATA_DELETE:
      return {
        ...state,
        Trainings: deleteTraining(state.Trainings, action.id),
      };

    default: return state;

  }
}

export default trainingsReducer;