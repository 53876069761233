export const AUTH_AUTHENTICATING = 'AUTH_AUTHENTICATING';
export const AUTH_AUTHENTICATED = 'AUTH_AUTHENTICATED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOADING = 'AUTH_LOADING';

export const COMPANIES_DATA_FETCH = 'COMPANIES_DATA_FETCH';
export const COMPANIES_DATA_RECEIVED = 'COMPANIES_DATA_RECEIVED';
export const COMPANIES_DATA_ERROR = 'COMPANIES_DATA_ERROR';

export const CONTACT_DATA_ADD = 'CONTACT_DATA_ADD';
export const CONTACT_DATA_EDIT = 'CONTACT_DATA_EDIT';
export const CONTACT_DATA_DELETE = 'CONTACT_DATA_DELETE';
export const CONTACTS_DATA_FETCH = 'CONTACTS_DATA_FETCH';
export const CONTACTS_DATA_RECEIVED = 'CONTACTS_DATA_RECEIVED';
export const CONTACTS_DATA_ERROR = 'CONTACTS_DATA_ERROR';
export const CONTACTS_HISTORY_RECEIVED = 'CONTACTS_HISTORY_RECEIVED';

export const EVENT_DATA_EDIT = 'EVENT_DATA_EDIT';
export const EVENT_DATA_DELETE = 'EVENT_DATA_DELETE';
export const EVENT_DATA_ADD = 'EVENT_DATA_ADD';
export const EVENTS_DATA_FETCH = 'EVENTS_DATA_FETCH';
export const EVENTS_DATA_RECEIVED = 'EVENTS_DATA_RECEIVED';
export const EVENTS_DATA_ERROR = 'EVENTS_DATA_ERROR';
export const EVENTS_HISTORY_RECEIVED = 'EVENTS_HISTORY_RECEIVED';
export const EVENTS_ATTEND_RECEIVED = 'EVENTS_ATTEND_RECEIVED';
export const EVENTS_ATTEND_FETCH = 'EVENTS_ATTEND_FETCH';

export const LEGAL_DATA_ADD = 'LEGAL_DATA_ADD';
export const LEGAL_DATA_EDIT = 'LEGAL_DATA_EDIT';
export const LEGAL_DATA_DELETE = 'LEGAL_DATA_DELETE';
export const LEGAL_DATA_FETCH = 'LEGAL_DATA_FETCH';
export const LEGAL_DATA_RECEIVED = 'LEGAL_DATA_RECEIVED';
export const LEGAL_DATA_ERROR = 'LEGAL_DATA_ERROR';
export const LEGAL_HISTORY_RECEIVED = 'LEGAL_HISTORY_RECEIVED';

export const INFO_TYPES_DATA_RECEIVED = 'INFO_TYPES_DATA_RECEIVED';
export const INFO_TYPES_DATA_FETCH = 'INFO_TYPES_DATA_FETCH';
export const NOTIFICATION_DATA_EDIT = 'NOTIFICATION_DATA_EDIT';
export const NOTIFICATION_DATA_DELETE = 'NOTIFICATION_DATA_DELETE';
export const NOTIFICATION_DATA_ADD = 'NOTIFICATION_DATA_ADD';
export const NOTIFICATIONS_DATA_FETCH = 'NOTIFICATIONS_DATA_FETCH';
export const NOTIFICATIONS_DATA_RECEIVED = 'NOTIFICATIONS_DATA_RECEIVED';
export const NOTIFICATIONS_DATA_ERROR = 'NOTIFICATIONS_DATA_ERROR';

export const ORGAS_DATA_FETCH = 'ORGAS_DATA_FETCH';
export const ORGAS_DATA_RECEIVED = 'ORGAS_DATA_RECEIVED';
export const ORGAS_DATA_ERROR = 'ORGAS_DATA_ERROR';

export const PERFORMANCE_DATA_ADD = 'PERFORMANCE_DATA_ADD';
export const PERFORMANCE_DATA_EDIT = 'PERFORMANCE_DATA_EDIT';
export const PERFORMANCE_DATA_DELETE = 'PERFORMANCE_DATA_DELETE';
export const PERFORMANCE_DATA_FETCH = 'PERFORMANCE_DATA_FETCH';
export const PERFORMANCE_DATA_RECEIVED = 'PERFORMANCE_DATA_RECEIVED';
export const PERFORMANCE_DATA_ERROR = 'PERFORMANCE_DATA_ERROR';
export const PERFORMANCE_HISTORY_RECEIVED = 'PEROFRMANCE_HISTORY_RECEIVED';
export const PERFORMANCE_DEV_DATA_RECEIVED = 'PERFORMANCE_DEV_DATA_RECEIVED';
export const PERFORMANCE_DEV_DATA_ADD = 'PERFORMANCE_DEV_DATA_ADD';
export const PERFORMANCE_DEV_DATA_DELETE = 'PERFORMANCE_DEV_DATA_DELETE';
export const PERFORMANCE_DEV_DATA_EDIT = 'PERFORMANCE_DEV_DATA_EDIT';
export const PERFORMANCE_DEV_HISTORY_RECEIVED = 'PERFORMANCE_DEV_HISTORY_RECEIVED';

export const QUESTION_DRAGGED = 'QUESTION_DRAGGED';
export const QUESTION_TITLE_SET = 'QUESTION_TITLE_SET';
export const QUESTION_DIALOG_STATE = 'QUESTION_DIALOG_STATE';
export const QUESTION_DELETE = 'QUESTION_DELETE';
export const QUESTION_OPTION_SET = 'QUESTION_OPTION_SET';
export const QUESTION_OPTION_ADD = 'QUESTION_OPTION_ADD';
export const QUESTION_OPTION_DELETE = 'QUESTION_OPTION_DELETE';

export const QUESTIONNAIRE_DATA_ADD = 'QUESTIONNAIRE_DATA_ADD';
export const QUESTIONNAIRES_DATA_FETCH = 'QUESTIONNAIRES_DATA_FETCH';
export const QUESTIONNAIRES_DATA_RECEIVED = 'QUESTIONNAIRES_DATA_RECEIVED';
export const QUESTIONNAIRES_DATA_ERROR = 'QUESTIONNAIRES_DATA_ERROR';

export const REGION_DATA_ADD = 'REGION_DATA_ADD';
export const REGION_DATA_EDIT = 'REGION_DATA_EDIT';
export const REGION_DATA_DELETE = 'REGION_DATA_DELETE';
export const REGIONS_DATA_FETCH = 'REGIONS_DATA_FETCH';
export const REGIONS_DATA_RECEIVED = 'REGIONS_DATA_RECEIVED';
export const REGIONS_DATA_ERROR = 'REGIONS_DATA_ERROR';

export const ROLE_DATA_ADD = 'ROLE_DATA_ADD';
export const ROLE_DATA_EDIT = 'ROLE_DATA_EDIT';
export const ROLE_DATA_DELETE = 'ROLE_DATA_DELETE';
export const ROLES_DATA_FETCH = 'ROLES_DATA_FETCH';
export const ROLES_DATA_RECEIVED = 'ROLES_DATA_RECEIVED';
export const ROLES_DATA_ERROR = 'ROLES_DATA_ERROR';

export const SUPPLIER_DATA_FETCH = 'SUPPLIER_DATA_FETCH';
export const SUPPLIER_DATA_RECEIVED = 'SUPPLIER_DATA_RECEIVED';
export const SUPPLIER_DATA_ERROR = 'SUPPLIER_DATA_ERROR';
export const SUPPLIER_DATA_RESET = 'SUPPLIER_DATA_RESET';
export const SUPPLIER_SELECT = 'SUPPLIER_SELECT';

export const TRAINING_DATA_ADD = 'TRAINING_DATA_ADD';
export const TRAINING_DATA_EDIT = 'TRAINING_DATA_EDIT';
export const TRAINING_DATA_DELETE = 'TRAINING_DATA_DELETE';
export const TRAININGS_DATA_FETCH = 'TRAININGS_DATA_FETCH';
export const TRAININGS_DATA_RECEIVED = 'TRAININGS_DATA_RECEIVED';
export const TRAININGS_DATA_ERROR = 'TRAININGS_DATA_ERROR';
export const TRAININGS_HISTORY_RECEIVED = 'TRAININGS_HISTORY_RECEIVED';

export const USER_DATA_ADD = 'USER_DATA_ADD';
export const USER_DATA_EDIT = 'USER_DATA_EDIT';
export const USER_DATA_DELETE = 'USER_DATA_DELETE';
export const USERS_DATA_FETCH = 'USERS_DATA_FETCH';
export const USERS_DATA_RECEIVED = 'USERS_DATA_RECEIVED';
export const USERS_DATA_ERROR = 'USERS_DATA_ERROR';
export const USERS_HISTORY_RECEIVED = 'USERS_HISTORY_RECEIVED';
